.services {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f7f9fc;
}
.services-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 80%;
}
.service {
  width: 40%;
  display: flex;
  margin: 5vh 1em;
  max-height: 12em;
  transition: 0.3s;
}
.service:hover {
  transform: translateX(20px);
  transition: 0.3s;
}
.service img {
  margin-right: 2%;
  border-radius: 25px;
  box-shadow: inset 0 0 10px #333;
}
.service .service-content {
}
.service-content h4 {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 33px;
  color: #f68a72;
  margin-left: 5%;
}
.service-content p {
  max-width: 21ch;
  color: #596778;

  font-size: 22px;
  font-family: "Poppins";
  font-weight: 400;
  text-align: start;
  margin-left: 5%;
}
@media (max-width: 70em) {
  .services {
    height: auto;
  }
  .services-box {
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .service {
    margin-block: 3em 0;
    width: 100%;
  }
  .service img {
    width: 50%;
  }
  .service .service-content {
    display: flex;
    flex-direction: column;
    z-index: 12;
    justify-content: space-around;
  }
  .service-content h4 {
    margin: 0;
    font-size: 2rem;
  }
  .service-content p {
    margin: 0;
    font-size: 16px;
  }
}
