.break {
  display: flex;
  align-items: center;
  height: 70vh;
  background: #fff3e0;
  /* margin-top: 40vh; */
  overflow: hidden;
  background-image: url(../../assets/breakBG.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position-y: center; */
}
.break img {
  height: 100%;
  width: 50%;
  clip-path: ellipse(100% 100% at 0% 50%);
}
.break .content {
  width: 100%;
  /* background: linear-gradient(90deg, #2980ba 0%, rgba(90, 194, 227, 0) 80%); */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  padding-inline: 10%;
}
.break .content h4 {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  margin: 0;
  color: #fff;
  width: 50%;
}
.break .content p {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #fff;
  width: 41ch;
  text-align: left;
  text-shadow: -1px -1px 5px #000;
}
@media (max-width: 70em) {
  .break {
    height: 50vh;
    position: relative;
    background-position: center;
  }
  .break::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000080;
  }
  .break .content h4 {
    font-size: 2rem;
    text-align: center;
    width: 100%;
    z-index: 10;
  }
  .break .content p {
    text-align: center;
    font-size: 1rem;
    width: 100%;
    z-index: 10;
    text-shadow: none;
  }
}
