.footer {
  max-height: 60vh;
  min-height: 60vh;
  position: relative;
  background: #5ac2e3;
  margin-top: 32vh;
}
.footer h5 {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  transform: translateY(10vh);
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  background: #fee6c2;
  height: 8vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.head {
  position: absolute;
  top: -65%;
  fill: #5ac2e3;
}
.footer-content {
  display: flex;
  justify-content: space-evenly;
  transform: translateY(-10%);
}

.waves-box {
  position: absolute;
  width: 100%;
  bottom: -4px;
}
.waves {
  position: relative;
  width: 100%;
  height: 20vh;
  /*Fix for safari gap*/
  min-height: 150px;
  max-height: 200px;
}

/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}

.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}

.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}

.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 10em;
  padding-block: 1em;
  height: 21em;
}

.leftf p {
  color: #fff;
}
.comp {
  width: 15em;
}
.def {
  font-size: 1rem;
  color: #fff;
  font-family: DM Sans;
  text-shadow: 1px 1px 1px #000;
}
.leftf p {
  font-size: 1rem;
  max-width: 16em;
  margin-top: 3em;
}
.centerf {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.folow {
  color: #fff;
  font-family: Ubuntu;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  margin: 0;
}
.icon {
  display: flex;
  flex-wrap: wrap;
  max-width: 16em;
  margin-block: 1em;
}
.ico {
  margin-inline: 1em;
  margin-block: 0.5em;
}
.iconn {
  transition: 0.3s;
}
.iconn:hover {
  fill: #fff;
}

.rightf {
  display: flex;
  /* justify-content: center; */
  align-items: flex-start;
  justify-content: space-between;
}

.link-foot {
  display: flex;
  flex-direction: column;
  list-style: none;
  max-width: 26em;
  flex-wrap: wrap;
  align-items: center;

  padding: 0;
}
.link-foot li {
  list-style: none;
  padding-inline: 1em;
  margin-block: 0.3em;
  text-decoration: none;
  color: #fefefe;
  text-align: center;
  font-family: Ubuntu;
  font-size: 1rem;
  font-style: normal;

  letter-spacing: 0.35px;
}

.link-foot li:nth-child(1) {
  font-size: 1.5rem;
  margin-bottom: 1em;
}
.copywrite {
  color: #fff;
  font-family: DM Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}
.drone-gif {
  width: 11em;
}

@media (max-width: 70em) {
  .footer {
    position: static;
    max-height: 160vh;
    min-height: 133vh;
    margin: 0;
    overflow: hidden;
  }
  .head {
    display: none;
  }
  .footer-content {
    transform: none;
    height: auto;
    flex-direction: column;
    padding-inline: 0;
  }
  .leftf {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  .comp {
    width: 10em;
  }
  .leftf p {
    font-size: 1rem;
  }
  .rightf {
    width: 100%;
    justify-content: space-evenly;
  }
  .waves-box {
    position: relative;
    bottom: -3em;
    /* height: 13em; */
    transform: scaleY(0.5);
  }
  .footer h5 {
    position: relative;
    transform: none;
    margin: 0;
    bottom: 0em;
  }
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }

  100% {
    transform: translate3d(85px, 0, 0);
  }
}
