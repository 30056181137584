.gallery-container {
  /* width: 80%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-inline: 10%;
}
.image-box {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
  -webkit-column-width: 35%;
  -moz-column-width: 35%;
  column-width: 35%;
}
.pic {
  max-width: 20em;
  margin-bottom: 2em;
  border-radius: 25px;
  overflow: hidden;
  /* box-shadow: -1px 1px 10px 0px #000; */
  /* padding: 0; */
  display: flex;
  transition: 0.5s ease;
}

.pic img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.pic img:hover {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}
@media (max-width: 70em) {
  .image-box {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }

  .pic {
    border-radius: 7px;
    margin-bottom: 0.5em;
    min-width: 5em;
  }
}
