.products {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: space-between;
  margin-bottom: 8em;
}
.products h2 {
}
.products p {
  width: 70%;
  margin-bottom: 5em;
  color: #596778;

  text-align: center;
}
.products-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 80%;
  padding-bottom: 1em;
}
.product {
  border-radius: 30px;
  background: #fff;
  box-shadow: 0px 8px 42.2px -3px rgba(0, 0, 0, 0.2);

  height: 30em;
  width: 20em;
  transition: 0.4s;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.product:hover {
  transform: scale(1.1);
  box-shadow: 0px 8px 42.2px 3px rgba(0, 0, 0, 0.4);
}
.product img {
  position: relative;
  top: -2px;
  width: 100%;
}
.product h4 {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  /* line-height: 41px; */
  text-align: center;
  color: #f68a72;
  margin: 10% 0 0 0;
}
.product p {
  margin: 5% 0 10% 0;
  color: #596778;
  width: 85%;
  font-size: 22px;
  font-family: "Poppins";
}
@media (max-width: 70em) {
  .products {
    height: auto;
    margin-bottom: 0;
  }
  .products p {
    margin-block: 2em;
  }
  .products-box {
    flex-direction: column;
    align-items: center;
    padding-top: 5em;
  }
  .product {
    height: 20em;
    width: 15em;
    margin-bottom: 3em;
  }
  .product img {
    background-size: cover;
    height: 10em;
  }
  .product h4 {
    font-size: 20px;
  }
  .product p {
    font-size: 16px;
    margin-bottom: 1em;
    margin-top: 1em;
  }
}
