.home {
  height: 100vh;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0;
  overflow: hidden;
  background-image: url(../../assets/bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  clip-path: ellipse(100% 62% at 50% 35%);
}
video {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
  filter: brightness(0.8);
}
.home h2 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  font-size: 8rem;
  line-height: 98px;
  margin-bottom: 0.3em;
  text-align: center;

  color: #ffffff;

  text-shadow: 1px 1px 14.3px rgba(0, 0, 0, 0.58);
}
.home h3 {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
}
.btn-home-container {
  margin-top: 50px;
  width: 40%;
  display: flex;
  justify-content: space-around;
}
.btn-home-container a img {
  width: 15em;
}
@media (max-width: 70em) {
  .home {
    max-width: 100%;
    height: 75vh;
    background-position-x: -32em;
    background-size: 290%;
    clip-path: ellipse(100% 60% at 50% 35%);
  }
  .home h2 {
    font-size: 4rem;
    margin-block: 0em;
    margin-top: 1em;
  }
  .home h3 {
    font-size: 16px;
    margin-top: 0em;
  }
  .btn-home-container {
    width: 50%;
    height: 24%;
    flex-direction: column;
    align-items: center;
    margin: 0;
  }
  .btn-home-container a img {
    width: 10em;
  }
}
