.GetApp {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-block: 8em;
}
.GetApp p {
  transform: translateY(-10vh);
  color: #596778;

  font-size: 22px;
  font-family: "Poppins";
}
span {
  width: 60%;
  height: 1px;
  background: #444;
  transform: translateY(-4vh);
}
.GetApp h2 {
  font-size: 72px;
  margin-block: 5vh;
}
.GetApp .btn-box {
  display: flex;
  justify-content: center;
  width: 35%;
  margin-block: 2em;
}
.GetApp .btn-box a {
  display: flex;
  justify-content: center;
  width: 20em;
  margin-inline: 1em;
}
.GetApp .btn-box a img {
  width: 100%;
}
@media (max-width: 70em) {
  .GetApp {
    height: auto;
  }
  .GetApp h2 {
    font-size: 3rem;
  }
  .GetApp span {
    width: 40%;
  }
  .phone-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30vh;
  }
  .phone-box img {
    width: 30%;
    height: 75%;
  }
  .phone-box img:nth-child(2) {
    transform: scale(1.2);
  }
  .GetApp .btn-box {
    flex-direction: column;
    margin-inline: 0;
    width: 100%;
    align-items: center;
  }
  .GetApp .btn-box a {
    width: 15em;
    margin-bottom: 2em;
  }
}
