.features {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 7em 12%;
}
.features p {
}
h2 {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  text-align: center;
  color: #5ac2e3;
  margin: 12vh 0 0 0;
}
p {
  color: #596778;

  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  text-align: center;

  margin: 5vh 0;
}
.features-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 5em;
}
.features-box .feature {
  width: 26%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.features-box .feature img {
  width: 5em;
}
.features-box .feature:hover img {
  animation: tilt-shaking 1s ease-in;
}
.features-box .feature h4 {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #f68a72;
  margin: 10px 0;
}
.features-box .feature p {
  color: #596778;

  font-size: 22px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  margin: 19px 0;
  max-width: 26ch;
}
@keyframes tilt-shaking {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  25% {
    transform: translate(5px, 5px) rotate(5deg);
  }
  50% {
    transform: translate(0, 0) rotate(0eg);
  }
  75% {
    transform: translate(-5px, 5px) rotate(-5deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}
@media (max-width: 70em) {
  .features {
    margin-block: 0;
    height: auto;
  }
  h2 {
    font-size: 2.5rem;
  }
  .feature p {
    font-size: 16px;
    /* overflow-y: hidden; */
    height: 20vh;
  }
  .features-box {
    flex-direction: column;
    max-width: 100%;
    margin-top: 0;
  }
  .features-box .feature p {
    height: auto;
  }
  .features-box .feature {
    width: 100%;
    margin-bottom: 5em;
  }
}
