.Navbar {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 20vh;
  z-index: 20;
  transition: 0.7s;
}
.chaged-color {
  background: #5ac3e3f0;
  height: 10vh;
  box-shadow: -5px 7px 35px #00000020;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
}
.Navbar img {
  width: 2.5em;
  margin-inline-end: 0.5em;
  /* transform: rotateZ(272deg); */
}
.Navbar h1 {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #fff;
  font-family: "Nunito", sans-serif;
}
ul {
  display: flex;
  justify-content: space-evenly;
  /* display: contents; */
  width: 40%;
}
ul li {
  list-style: none;
}
ul li a {
  text-decoration: none;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #fff;
  font-family: "Nunito", sans-serif;
}
ul li a::before {
  content: "";
  position: relative;
  bottom: -30px;
  width: 0%;
  height: 1px;
  background: #fff;
  display: block;
  transition: 0.2s;
}
ul li a:hover::before {
  width: 100%;
}
.get-app {
  padding: 12px 32px;
  background: #ffd466;
  color: #000;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
  font-family: "Nunito", sans-serif;
  border-radius: 40px;
  transition: 0.4s;
}
.chaged-color .get-app {
  box-shadow: 0 0 5px #ffd466;
}
.get-app:hover {
  background: #ffb700;
}
.chaged-color .get-app:hover {
  box-shadow: 0 0 5px #ffb700;
}
@media (max-width: 70em) {
  .Navbar {
    height: 10vh;
    justify-content: space-between;
  }
  .chaged-color {
    height: 8vh;
  }
  .Navbar h1 {
    margin-left: 1em;
    font-size: 16px;
  }
  ul {
    display: none;
  }
  .get-app {
    margin-right: 1em;
    font-size: 13px;
    padding: 8px 10px;
  }
}
